<template>
  <div class="Header-wrapper">
    <div>
      <img src="../../assets/images/logo.png" class="logo" alt="">
      <span class="f16 blackfont name">
               异州共创家——平台后台管理
            </span>
    </div>
    <div class="tool">
            <span v-if="!login">
               <span class="mr16 ver-middle f14"><router-link :to="{path:'/login'}">登录</router-link></span>
            </span>
      <el-popover
        placement="bottom"
        v-if="login"
        width="157"
        trigger="hover">
                <span v-if="login" class="curpor" style="display: inline-block " slot="reference">
                  <el-avatar size="small" :src="userInfo.avatar_url || circleUrl"/>
                  <span class="f14 grayfont-65 " style="vertical-align: middle">设置</span>
                </span>
        <div class="popover-block">
          <div>
            <div>{{userInfo.nickname}}</div>
            <div class="grayfont-32 mt10">账号：{{userInfo.username}}</div>
          </div>
          <ul>
            <li @click="updatePassword()">
              <i class="iconfont icon-mimasuo"/> <span>修改密码</span>
            </li>
            <li @click="loginOut()">
              <i class="iconfont icon-tuichu"/> <span>退出登录</span>
            </li>
          </ul>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
  let vm;
  import {getToken, removeToken} from '../../utils/cookieToken'

  export default {
    name: "Header",
    data() {
      return {
        circleUrl: require('../../assets/images/default-avatar.png'),
        userInfo: {},
        sheqv_address: '',
        login: true,
        token:getToken(),
      }
    },
    computed:{
      monitor () {
        return this.$store.state.user.userinfo
      }
    },
    watch:{
      monitor () {
        vm.userInfo = vm.$store.state.user.userinfo;
        vm.$forceUpdate();
      }
    },
    mounted() {
      vm = this;
      vm.getUserInfo();

    },
    created() {
      if (!this.token) {
        this.$router.push('/login');
        return false;
      }
    },
    methods: {
      getUserInfo(){
        if(vm.globalFun.localStorage_get('platform_userinfo')){
          vm.userInfo = vm.globalFun.localStorage_get('platform_userinfo');
        }
      },

      updatePassword() {
        vm.$router.push({name: 'UpdatePassword'})
      },

      loginOut() {
        removeToken();
        vm.$store.commit('user/del_userinfo');
        vm.$store.commit('tagsView/delete_tagsView');
        vm.$store.commit('sideBar/del_sidebar');
        vm.$router.push('/login')
      },
    }
  }
</script>

<style lang="less" scoped>
  @import "../../assets/css/variables";

  .Header-wrapper {
    width: 100%;
    height: 48px;
    background-color: #FFFFFF;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.12);

    .logo {
      width: 218px;
      height: 36px;
      object-fit: contain;
      margin-right: 15px;
      vertical-align: middle;
    }

    .name {
      border-left: 1px solid rgba(0, 0, 0, 0.32);
      padding-left: 15px;
      vertical-align: middle;
      display: inline-block;
    }

    .iconfont {
      color: #595959;
      font-size: 16px;
      margin-right: 26px;
      vertical-align: middle;
    }

    .avtar {

    }
  }

  .popover-block {
    ul {
      border-top: 1px solid @line;
      margin-top: 12px;
      padding: 10px 0 0;

      li {
        padding: 7px 5px;
        cursor: pointer;

        .iconfont {
          font-size: 14px;
          color: #ACACAC;
          margin-right: 12px;
        }
      }
    }
  }

  /deep/ .el-avatar--small {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    vertical-align: middle;
  }

  /deep/ .el-popover {
    padding: 16px !important;
  }
</style>
