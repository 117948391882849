<template>
  <div class="sidebar-wrapper">
    <div class="home-box curpor" :class="defaultActive==='Home'?'active':''" @click="$router.push({name:'Home'})">
      <i class="iconfont icon-a-zu30"></i>
      <span>首页</span>
    </div>
    <el-menu
      :default-active="defaultActive"
      ref="menu"
      :unique-opened="true"
      class="el-menu-vertical-demo"
      background-color="#515970"
      text-color="#ffffff"
      @select="handleSelect"
      @open="handleOpen"
      @close="handleClose">
      <el-submenu v-for="(item) in sidebarItem" v-if="item.status === 1" :index="item.parent_name">
        <template slot="title">
          <i class="iconfont" :class="item.icon"></i>
          <span>{{item.title}}</span>
        </template>
        <el-menu-item v-for="(sub) in item.children" v-if="sub.status === 1" :index="sub.name">
          {{sub.title}}
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
  let vm;
  export default {
    name: "SideBar",
    data() {
      return {
        defaultActive: this.$route.meta.parentName,
        sidebarItem: [],
        defaultProps: {
          children: 'children',
          label: 'title'
        },
      }
    },
    computed: {
      sidebar() {
        return this.$store.state.sideBar.sidebar;
      },

      active(){
        return this.$route.meta.parentName;
      },
    },
    watch: {
      sidebar(val, old) {
        vm.sidebarItem = val;
        vm.$store.commit('sideBar/set_sidebar', vm.sidebarItem);
        vm.$forceUpdate();
      },

      active(val, old) {
        vm.defaultActive = val;
      }
    },
    mounted() {
      vm = this;
      vm.init();
    },
    methods: {
      init() {
        if (vm.globalFun.localStorage_get('sidebar')) {
          vm.sidebarItem = vm.globalFun.localStorage_get('sidebar')
        }
        vm.$store.commit('sideBar/set_sidebar', vm.sidebarItem);
      },

      handleOpen() {
      },

      handleClose() {
      },

      handleSelect(index, indexPath) {
        vm.$router.push({name: index});
      },

    }
  }
</script>

<style scoped lang="less">
  @import "../../assets/css/variables";

  .sidebar-wrapper {
    width: 214px;
    height: 100%;
    background: #515970;
    padding: 24px 0;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #e9e9e9;
    }

    &::-webkit-scrollbar-thumb {
      background: #D1D1D1;
      border-radius: 6px;
    }

    .home-box {
      width: 186px;
      height: 40px;
      border-radius: 6px;
      line-height: 40px;
      font-size: 14px;
      color: #FFFFFF;
      padding: 0 20px;
      margin-left: 11px;
    }

    .home-box.active {
      background: #FF912E;
    }

    .iconfont {
      color: #FFFFFF;
      margin-right: 12px;
    }

    .el-menu {
      border-right: 0;
      font-size: 14px;
    }

    /deep/ .el-submenu.is-active {
      .el-submenu__title {
        color: @orange !important;

        i {
          color: @orange;
        }

      }
    }

    .el-menu-item.is-active {
      color: #ffffff;
      background: @orange !important;

    }

    /deep/ .el-submenu {
      margin-top: 10px !important;

      .el-menu-item {
        min-width: 100%;
        height: 40px;
        line-height: 40px;
        padding: 0 14px;
        margin-top: 10px;
      }

    }

    /deep/ .el-submenu__title {
      height: 40px;
      line-height: 40px;
      padding: 0 30px !important;

      :hover {
        background-color: transparent;
      }
    }

    /deep/ .el-menu-item {
      padding-left: 68px !important;
    }
  }


</style>
