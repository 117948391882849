import Layout from '@/layout'

export const constantRouterMap = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home'),
        meta: {title: '首页', keepAlive: false, parentName: 'Home'}
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    method: {title: '登录', parentName: 'Login'},
    component: () => import('@/views/Login')
  },
  {
    path: '/content',
    component: Layout,
    method: {title: '内容管理', parentName: 'ContentManage'},
    children: [
      {
        path: '/content',
        name: 'Content',
        component: () => import('@/views/content/index'),
        meta: {title: '内容列表', keepAlive: false, parentName: "Content"}
      },
      {
        path: '/editContent',
        name: 'EditContent',
        component: () => import('@/views/content/editContent'),
        meta: {title: '内容编辑', keepAlive: false, parentName: "Content"}
      },
    ],
  },
  {
    path: '/ad',
    component: Layout,
    method: {title: '广告管理', parentName: 'adManage'},
    children: [
      {
        path: '/ad',
        name: 'AD',
        component: () => import('@/views/ad/index'),
        meta: {title: '广告位管理', keepAlive: false, parentName: "AD"}
      },
      {
        path: '/adDetail',
        name: 'AdDetail',
        component: () => import('@/views/ad/adDetail'),
        meta: {title: '广告详情', keepAlive: false, parentName: "AD"}
      },
    ],
  },
  {
    path: '/integral',
    component: Layout,
    method: {title: '积分管理', parentName: 'IntegralManage'},
    children: [
      {
        path: '/integral',
        name: 'Integral',
        component: () => import('@/views/integral/index'),
        meta: {title: '积分总览', keepAlive: false, parentName: 'Integral'}
      },
      {
        path: '/integralTaskRecords',
        name: 'IntegralTaskRecords',
        component: () => import('@/views/integral/integralTaskRecords'),
        meta: {title: '积分任务流水', keepAlive: false, parentName: 'IntegralTaskRecords'}
      },
      {
        path: '/exchangeRecords',
        name: 'ExchangeRecords',
        component: () => import('@/views/integral/exchangeRecords'),
        meta: {title: '积分兑换流水', keepAlive: false, parentName: 'ExchangeRecords'}
      },
    ],
  },
  {
    path: '/financial',
    component: Layout,
    method: {title: '财务管理', parentName: 'FinancialManage'},
    children: [
      {
        path: '/routingRecords',
        name: 'RoutingRecords',
        component: () => import('@/views/financial/routingRecords'),
        meta: {title: '商家积分明细', keepAlive: false, parentName: 'RoutingRecords'}
      },
      {
        path: '/transaction',
        name: 'Transaction',
        component: () => import('@/views/financial/transaction'),
        meta: {title: '提现打款记录', keepAlive: false, parentName: 'Transaction'}
      },
      {
        path: '/withdrawal',
        name: 'Withdrawal',
        component: () => import('@/views/financial/withdrawal'),
        meta: {title: '提现打款', keepAlive: false, parentName: 'Withdrawal'}
      },
    ]
  },
  {
    path: '/community',
    component: Layout,
    meta: {title: '社区管理', parentName: 'CommunityManage'},
    children: [
      {
        path: '/createCommunity',
        name: 'CreateCommunity',
        component: () => import('@/views/community/createCommunity'),
        meta: {title: '社区创建', keepAlive: false, parentName: 'CreateCommunity'},
      },
      {
        path: '/communitySubsidiary',
        name: 'CommunitySubsidiary',
        component: () => import('@/views/community/communitySubsidiary'),
        meta: {title: '社区明细', keepAlive: false, parentName: 'CommunitySubsidiary'},
      },
      {
        path: '/detail',
        name: 'Detail',
        component: () => import('@/views/community/detail'),
        meta: {title: '社区详情', keepAlive: false, parentName: 'CreateCommunity'},
      },
      {
        path: '/villageSet',
        name: 'VillageSet',
        component: () => import('@/views/community/permission'),
        meta: {title: '功能配置', keepAlive: false, parentName: 'CreateCommunity'},
      },
    ],
  },
  {
    path: '/integralExchange',
    component: Layout,
    meta: {title: '积分兑换管理', parentName: 'IntegralExchange'},
    children:[
      {
        path: '/merchants',
        name: 'Merchants',
        component: () => import('@/views/integralExchange/merchants'),
        meta: {title: '商家管理', keepAlive: false, parentName: 'Merchants'},
      },
      {
        path: '/open',
        name: 'OpenMerchants',
        component: () => import('@/views/integralExchange/openMerchants'),
        meta: {title: '开通商家', keepAlive: true, parentName: 'Merchants'},
      },
      {
        path: '/products',
        name: 'Products',
        component: () => import('@/views/integralExchange/products'),
        meta: {title: '商品管理', keepAlive: false, parentName: 'Products'},
      },
      {
        path: '/addProducts',
        name: 'AddProducts',
        component: () => import('@/views/integralExchange/addProducts'),
        meta: {title: '添加商品', keepAlive: false, parentName: 'Products'},
      },
      {
        path: '/productDetails',
        name: 'ProductDetails',
        component: () => import('@/views/integralExchange/productDetails'),
        meta: {title: '商品详情', keepAlive: false, parentName: 'Products'},
      },
      {
        path: '/exchangeDetails',
        name: 'ExchangeDetails',
        component: () => import('@/views/integralExchange/exchangeDetails'),
        meta: {title: '兑换明细', keepAlive: false, parentName: 'ExchangeDetails'},
      },
    ]
  },


  {
    path: '/organization',
    component: Layout,
    meta: {title: '机构管理', parentName: 'OrganizationManage'},
    children: [
      {
        path: '/platform-organization',
        name: 'PlatformOrganization',
        component: () => import('@/views/organization/platform'),
        meta: {title: '平台机构', keepAlive: false, parentName: 'PlatformOrganization'},
      },
      {
        path: '/community-organization',
        name: 'CommunityOrganization',
        component: () => import('@/views/organization/community'),
        meta: {title: '社区机构', keepAlive: false, parentName: 'CommunityOrganization'},
      },
      {
        path: '/create',
        name: 'Create',
        component: () => import('@/views/organization/create'),
        meta: {title: '创建机构', keepAlive: false, parentName: 'PlatformOrganization'},
      },
      {
        path: '/platformDetail',
        name: 'PlatformDetail',
        component: () => import('@/views/organization/platformDetail'),
        meta: {title: '平台机构详情', keepAlive: false, parentName: 'PlatformOrganization'},
      },
      {
        path: '/communityDetail',
        name: 'CommunityDetail',
        component: () => import('@/views/organization/communityDetail'),
        meta: {title: '社区机构详情', keepAlive: false, parentName: 'CommunityOrganization'},
      },


      {
        path: '/settleIn',
        name: 'settleIn',
        component: () => import('@/views/organization/settleIn'),
        meta: { title: '机构入驻审核', keepAlive:false, shortName:'settleIn',parentName:'settleIn', },
      },
      {
        path: '/goodsOnShelves',
        name: 'goodsOnShelves',
        component: () => import('@/views/organization/goodsOnShelves'),
        meta: { title: '商品上架审核', keepAlive:false, shortName:'goodsOnShelves',parentName:'goodsOnShelves', },
      },
      {
        path: '/mechanismOnShelves',
        name: 'mechanismOnShelves',
        component: () => import('@/views/organization/mechanismOnShelves'),
        meta: { title: '机构上下架管理', keepAlive:false, shortName:'mechanismOnShelves',parentName:'mechanismOnShelves', },
      },
      {
        path: '/orgUpDownDetail',
        name: 'orgUpDownDetail',
        component: () => import('@/views/organization/orgUpDownDetail'),
        meta: { title: '商家详情', keepAlive:false, shortName:'orgUpDownDetail',parentName:'orgUpDownDetail', },
      },
      {
        path: '/showProductss',
        name: 'showProductss',
        component: () => import('@/views/organization/showProductss'),
        meta: { title: '商品详情', keepAlive:false, shortName:'showProductss',parentName:'goodsOnShelves', },
      },
    ],
  },

  {
    path: '/platform',
    component: Layout,
    meta:{title:'平台管理',parentName:'platform'},
    children: [
      {
        path: '/dataPlatform',
        name: 'dataPlatform',
        component: () => import('@/views/platform/dataPlatform'),
        meta: { title: '数据平台管理', keepAlive: false,shortName:'dataPlatform',parentName:'dataPlatform' }
      },
      {
        path: '/operationPlatform',
        name: 'operationPlatform',
        component: () => import('@/views/platform/operationPlatform'),
        meta: { title: '运营平台管理', keepAlive: false,shortName:'operationPlatform',parentName:'operationPlatform' }
      },
      {
        path: '/details',
        name: 'details',
        component: () => import('@/views/platform/details'),
        meta: { title: '运营平台管理详情', keepAlive: false, parentName:'operationPlatform' }
      },
    ]
  },

  {
    path: '/dataManage',
    component: Layout,
    meta: { title: '数据管理',parentName:'dataManage'},
    children: [
      {
        path: '/userData',
        name: 'userData',
        component: () => import('@/views/dataManage/userData'),
        meta: { title: '用户数据', keepAlive:false, shortName:'userData',parentName:'userData' },
      },
      {
        path: '/platformData',
        name: 'platformData',
        component: () => import('@/views/dataManage/platformData'),
        meta: { title: '平台数据', keepAlive:false, shortName:'platformData',parentName:'platformData' },
      },
      {
        path: '/mechanismData',
        name: 'mechanismData',
        component: () => import('@/views/dataManage/mechanismData'),
        meta: { title: '机构数据', keepAlive:false, shortName:'mechanismData',parentName:'mechanismData' },
      },
    ]
  },

  {
    path: '/resources',
    component: Layout,
    meta: { title: '资源管理',parentName:'resources'},
    children: [
      {
        path: '/selfSupport',
        name: 'selfSupport',
        component: () => import('@/views/resources/selfSupport'),
        meta: { title: '自营商品库', keepAlive:false, shortName:'selfSupport',parentName:'selfSupport', },
      },
      {
        path: '/addProductss',
        name: 'addProductss',
        component: () => import('@/views/resources/addProductss'),
        meta: {title: '添加或维护商品', keepAlive: false, parentName: 'selfSupport'},
      },
      {
        path: '/showProductssSupport',
        name: 'showProductssSupport',
        component: () => import('@/views/resources/showProductss'),
        meta: {title: '商品详情', keepAlive: false, parentName: 'selfSupport'},
      },
      {
        path: '/purchase',
        name: 'purchase',
        component: () => import('@/views/resources/purchase'),
        meta: { title: '采购订单', keepAlive:false, shortName:'purchase',parentName:'purchase', },
      },
    ]
  },

  {
    path: '/systemSet',
    component: Layout,
    meta: {title: '系统设置', parentName: 'systemSet'},
    children: [
      {
        path: '/permission',
        name: 'Permission',
        component: () => import('@/views/system/permission'),
        meta: {title: '权限编辑', keepAlive: false, parentName: 'RoleManage'}
      },
      {
        path: '/roleManage',
        name: 'RoleManage',
        component: () => import('@/views/system/roleManage'),
        meta: {title: '角色管理', keepAlive: false, parentName: 'RoleManage'}
      },
      {
        path: '/account',
        name: 'Account',
        component: () => import('@/views/system/account'),
        meta: {title: '账户管理', keepAlive: false, parentName: 'Account'}
      },
      {
        path: '/function',
        name: 'Function',
        component: () => import('@/views/system/function'),
        meta: {title: '功能管理', keepAlive: false, parentName: 'Function'}
      },
      {
        path: '/nodeManage',
        name: 'NodeManage',
        component: () => import('@/views/system/nodeManage'),
        meta: {title: '节点管理', keepAlive: false, parentName: 'Function'}
      },
      {
        path: '/addNode',
        name: 'AddNode',
        component: () => import('@/views/system/addNode'),
        meta: {title: '添加节点', keepAlive: false, parentName: 'Function'}
      },
      {
        path: '/updatePassword',
        name: 'UpdatePassword',
        component: () => import('@/views/system/updatePassword'),
        meta: {title: '修改密码', keepAlive: false, parentName: 'UpdatePassword', shortName: 'XGMM'},
      },

    ]
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/components/NotFound')
  }
];
