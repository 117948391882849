import Cookies from 'js-cookie'

const TokenKey = 'gcj_platform_token';

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}


//创建有效期为7天的cookie
export function setToken_day(token,day){
    return Cookies.set(TokenKey, token, { expires: day || 7 });
}

//为指定页创建有效期7天的cookie
export function setToken_day_path(token,day,path){
    return Cookies.set(TokenKey, token, { expires: day || 7, path: path });
}


export function removeToken() {
    return Cookies.remove(TokenKey)
}

//删除指定页cookie
export function removeToken_path(path) {
    return Cookies.remove(TokenKey, { path: path });
}
