<template>
  <div class="TagsView">
    <el-scrollbar ref="scrollContainer" :vertical="false" class="scroll-container" v-if="tagsView.length > 0">
        <span class="tags-item curpor"
              :class="current_active===item.name?'activeTags':''"
              v-for="(item,index) in tagsView" :key="index"
              @click="handleTags(item)">
          {{item.title}}
          <i class="el-icon-close" @click.stop="handleClose(item,index)"></i>
        </span>
    </el-scrollbar>
  </div>
</template>

<script>
  let vm;
  export default {
    name: "TagsView",
    computed: {
      visitedViews() {
        return this.$store.state.tagsView.tags
      },
    },
    watch: {
      visitedViews() {
        vm.tagsView = vm.$store.state.tagsView.tags;
        vm.$forceUpdate();
      },
      $route: {
        handler(val, oldval) {
          vm.current_active = val.name ? val.name : '';
        },
        // 深度观察监听
        deep: true
      },
      current_active(val, old) {

      }
    },
    data() {
      return {
        tagsView: [],
        current_active: '',
        isDown: false,
      }
    },
    mounted() {
      vm = this;
      vm.getTagsView();
      vm.current_active = vm.$route.name;
    },
    methods: {
      getTagsView() {
        if (sessionStorage.getItem('tags')) {
          vm.tagsView = JSON.parse(sessionStorage.getItem('tags'));
          vm.$store.state.tagsView.tags = JSON.parse(sessionStorage.getItem('tags'));
        }
      },

      handleClose(item, index) {
        let length = vm.tagsView.length - 1;
        vm.closeTags(item);
        if (item.name !== vm.current_active) {
          return;
        }
        // 判断：如果index和length是一样的，那就代表都是一样的长度，就是最后一位，那就往左跳转一个
        if (index === length) {
          //再判断：如果length=0，也就是说你删完了所有标签
          if (length === 0) {
            //那么再判断：如果当前路由不等于index，也就是我首页的路由
            if (vm.$route.path !== '/') {
              vm.$router.push('/')
            }
          } else {
            //那么，如果上面的条件都不成立，没有length=0.也就是说你还有好几个标签，并且你删除的是最后一位标签，那么就往左边挪一位跳转路由
            vm.$router.push(
              {
                name: vm.tagsView[index - 1].name,
                query: vm.tagsView[index - 1].query ? vm.tagsView[index - 1].query : ''
              }
            )
          }
        } else {
          // 如果你点击不是最后一位标签，点的前面的，那就往右边跳转
          vm.$router.push(
            {
              name: vm.tagsView[index].name,
              query: vm.tagsView[index].query ? vm.tagsView[index].query : ''
            }
          )
        }
      },

      closeTags(obj) {
        vm.$store.commit('tagsView/delete_tags', obj);
      },

      handleTags(item) {
        if (item.name !== vm.current_active) {
          vm.$router.push({name: item.name, query: item.query ? item.query : ''})

        }
      },

    }
  }
</script>

<style scoped lang="less">
  @import "../../assets/css/variables";

  .TagsView {
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    font-size: 14px;
    border-radius: 6px;
    padding: 7px;
    margin-bottom: 24px;
    position: sticky;
    top: -23px;
    z-index: 10;
    box-shadow: 0 3px 6px #e5e5e5;

    .tags-item {
      border-radius: 6px;
      background: #F2F2F2;
      text-align: center;
      padding: 4px 16px;
      display: inline-block;
      margin-right: 14px;

      :last-child {
        margin-right: 0;
      }

      .el-icon-close {
        margin-left: 10px;
      }
    }

    .activeTags {
      color: @orange !important;
      background: transparent;
    }

    .scroll-container {
      white-space: nowrap;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 34px;
      border-radius: 6px 6px 0 0;

      ::v-deep {
        .el-scrollbar__bar {
          bottom: 0;
        }

        .el-scrollbar__wrap {
          height: 38px;

          &::-webkit-scrollbar {
            display: none;
          }

          &::-webkit-scrollbar-thumb {
            background: #D1D1D1;
            border-radius: 6px;
            width: 6px;
          }
        }
      }
    }

    /deep/ .el-scrollbar__wrap {
      overflow: auto;
    }
  }
</style>
