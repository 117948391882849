import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import VueCropper from 'vue-cropper'
import globalFun from './utils/globalFun'
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/index.css';

//富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.config.productionTip = false;
Vue.prototype.globalFun = globalFun;

Vue.use(ElementUI);
Vue.use(VueCropper);
Vue.use(VueQuillEditor);


process.env.NODE_ENV === "production" && Sentry.init({  //process.env.NODE_ENV === "production" &&
  dsn: 'http://1505bb720a5b41a8903c1f282b4b92a4@116.62.243.43:9000/10',
  integrations: [new Integrations.Vue({Vue, attachProps: true})],
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

// router.beforeEach( (to, from, next) => {
//   console.log(to,from)
//   next()
//   // router.push({name: '404'})
// })
