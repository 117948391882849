import api from './index'
// axios
import request from '../utils/request'

export function products(params) {
  return request({
    url:api.products,
    method: 'get',
    params
  })
}

export function addProducts(data){
  return request({
    url:api.addProducts,
    method: 'post',
    data
  })
}

export function purchaseOrders(params) {
  return request({
    url:api.purchaseOrders,
    method: 'get',
    params
  })
}
export function purchasecancelOrders(params, id) {
  return request({
    url:api.purchasecancel+"/"+ id,
    method: 'put',
    params
  })
}

export function topAdd(data) {
  return request({
    url:api.topAdd,
    method: 'post',
    data
  })
}

export function top(params) {
  return request({
    url:api.top,
    method: 'get',
    params
  })
}

export function topShow(params, id) {
  return request({
    url:api.topShow + `${id}`,
    method: 'get',
    params
  })
}

export function topDel(data, id) {
  return request({
    url:api.topDel + `/${id}`,
    method: 'delete',
    data
  })
}

export function topPut(data, id) {
  return request({
    url:api.top + `/${id}`,
    method: 'put',
    data
  })
}

export function relation(data) {
  return request({
    url:api.relation,
    method: 'post',
    data
  })
}

export function purchasesure(data, id) {
  return request({
    url:api.purchasesure+"/"+id,
    method: 'post',
    data
  })
}

export function purchaseupdate(data) {
  return request({
    url:api.purchaseupdate,
    method: 'post',
    data
  })
}

export function UNrelation(data) {
  return request({
    url:api.UNrelation,
    method: 'post',
    data
  })
}

export function relationS(params, id) {
  return request({
    url:api.relationS + '/' + id,
    method: 'get',
    params
  })
}

export function status(data) {
  return request({
    url:api.status,
    method: 'put',
    data
  })
}

export function getPOders(params, id) {
  return request({
    url:api.purchaseOrders + '/' + id,
    method: 'get',
    params
  })
}

export function delProducts(data, id){
  return request({
    url:api.delProducts,
    method: 'delete',
    data
  })
}

export function products_detail(data, id) {
  return request({
    url:api.products_detail.url + '/' + id,
    method: api.products_detail.type,
    data
  })
}


export function products_checklist(params) {
  return request({
    url:api.products_checklist.url,
    method: api.products_checklist.type,
    params
  })
}

export function products_checkbatch(params) {
  return request({
    url:api.products_checkbatch.url,
    method: api.products_checkbatch.type,
    params
  })
}


export function platform_organ(params) {
  return request({
    url:api.platform_organ.url,
    method: api.platform_organ.type,
    params
  })
}

export function updateStateOrgan(id) {
  return request({
    url: api.platform_organ_updatestate.url+`/${id}`,
    method: api.platform_organ_updatestate.type,
  })
}

export function auditOrgan(params) {
  return request({
    url: api.platform_organ_audit.url,
    method: api.platform_organ_audit.type,
    params
  })
}

export function platform_organ_detail(params, id) {
  return request({
    url: api.platform_organ_show.url + `/${id}`,
    method: api.platform_organ_show.type,
    params
  })
}

export function getBinding(params) {
  return request({
    url: api.getBinding.url,
    method: api.getBinding.type,
    params
  })
}


export function loginIn(data) {
  return request({
    url: api.login.url,
    method: api.login.type,
    data
  })
}

export function user(params) {
  return request({
    url: api.user.url,
    method: api.user.type,
    params
  })
}

export function password(data) {
  return request({
    url: api.password.url,
    method: api.password.type,
    data
  })
}

export function search(params) {
  return request({
    url: api.search.url,
    method: api.search.type,
    params
  })
}

export function upload(data) {
  return request({
    url:api.upload.url,
    method: api.upload.type,
    timeout:0,
    data
  })
}
