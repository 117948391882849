const api = {
  login: {url: '/auth/login', type: 'POST',},

  password: {url: '/password', type: 'POST',},

  user:{url: '/user', type: 'GET'},

  search:{url: '/search', type: 'GET'},

  upload:{url: '/common/upload', type: 'POST'},

  account: {url: '/account', type: 'GET'},    //账号列表

  add_account:{url: '/account', type: 'POST'},   //添加账号

  del_account:{url:'/account/delete',type:'POST'}, //删除账号

  update_account:{url:'/account/update',type:'POST'}, //编辑账号

  products_checklist: {url: '/products/check/list', type:'GET'}, //商品审核列表
  products_detail: {url: '/products', type:'GET'}, //商品详情
  products_checkbatch: {url: '/products/check/batch', type:'POST'}, //商品审核

  platform_organ: {url: '/organ', type: 'GET'},  //平台机构列表

  platform_organ_audit: {url: '/organs/batch-check', type:'put'}, //机构批量审核
  platform_organ_updatestate: {url: '/organs/state', type:'put'}, //机构状态修改

  platform_organ_show: {url: '/organ', type: 'GET'},  //平台机构详情

  getBinding: {url:'organs/binding/get', type: 'get'},//获取绑定人员

  delete_organ: {url: '/organ/delete', type: 'POST'},   //删除平台机构

  village_organ: {url: '/villageorgan', type: 'GET'},   //社区机构列表

  village_organ_show: {url: '/villageorgan/show', type: "GET"},   //社区机构详情

  create_organ: {url: '/organ', type: 'POST',},    //创建机构

  content: {url: '/content', type: 'GET'},   //内容列表

  content_show: {url: '/content', type: 'GET'},   //内容详情

  del_content: {url: '/content/delete', type: 'POST'},   //删除内容

  update_content: {url: '/content/update', type: 'POST'},   //删除内容

  add_content: {url: '/content', type: 'POST'},   //新增内容

  ad:{url:'/ad',type:'GET'},    //广告列表

  new_ad:{url:'/ad',type:'POST'},    //创建广告

  delete_ad:{url:'/ad/delete',type:'POST'},    //删除广告

  update_ad:{url:'/ad/update',type:'POST'},    //编辑广告

  get_role:{url:'/role',type:'GET'},  //角色列表

  set_role:{url:'/role',type:'POST'},  //添加角色

  del_role:{url:'/role/delete',type:'POST'},  //删除角色

  update_role:{url:'/role/update',type:'POST'},  //编辑角色

  permission:{url:'/permission',type:'GET'},  //权限列表

  set_permission:{url:'/permission',type:'POST'},  //权限变更

  village:{url:'/village',type:'GET'},  //社区列表

  village_data:{url:'/village-data',type:'GET'},  //社区列表

  platformListAll:{url:'/platform/list',type:'GET'},  //社区列表 不分页

  villageAll:{url:'/village-all',type:'GET'},  //社区列表 不分页

  village_show:{url:'/village/show',type:'GET'},  //社区详情

  create_village:{url:'/village',type:'POST'},  //创建社区

  village_update:{url:'/village/update',type:'POST'},   //修改社区

  get_village_setting:{url:'/setting',type:'GET'},   //已选功能配置

  set_village_setting:{url:'/setting',type:'POST'},   //功能配置变更

  menu:{url:'/menu',type:'GET'},   //节点列表

  add_menu:{url:'/menu',type:'POST'},   //添加节点

  menu_show:{url:'/menu/show',type:'GET'},   //节点详情

  update_menu:{url:'/menu/update',type:'POST'},   //编辑节点

  del_menu:{url:'/menu/delete',type:'POST'},   //删除节点

  menu_sort:{url:'/menu/sort',type:'POST'},  //节点排序

  task:{url:'/task',type:'GET'},  //任务列表

  products:'/products', //商品列表,

  addProducts: '/products',//添加商品

  delProducts: '/products',//删除商品

  top: 'top',//一级平台

  topAdd: 'top',//添加一级平台

  relation: 'top/relation',//二级平台

  UNrelation: 'top/un-relation',//解绑二级平台

  topDel:'top',//删除平台

  topShow: 'top/show/',

  relationS: 'top/relation/show',

  status: 'products/status',//修改商品状态

  purchasesure: 'purchase-sure',//订单付款

  purchaseOrders: 'purchase-orders',//采购订单列表

  purchaseupdate: 'purchase-update',//修改订单

  purchasecancel: 'purchase-cancel',//取消订单

};

export default api;
