<template>
    <div class="app-main">
        <tags-view v-if="tagsView.length > 0"></tags-view>
        <div>
            <transition name="fade-transform" mode="out-in">
                <router-view  v-if="!$route.meta.keepAlive"/>
            </transition>
        </div>

        <div>
            <transition name="fade-transform" mode="out-in">
                <keep-alive>
                    <router-view v-if="$route.meta.keepAlive"></router-view>
                </keep-alive>
            </transition>
        </div>
    </div>
</template>

<script>
    import TagsView from "./TagsView";

    export default {
        name: "AppMain",
        components: {TagsView},
        data(){
            return{
                tagsView:[],
            }
        },
        computed: {
            key() {
                return this.$route.path
            },
            visitedViews() {
                return this.$store.state.tagsView.tags
            },
        },
        watch:{
            visitedViews(){
                this.tagsView = this.$store.state.tagsView.tags;
            },
            $route:{
                handler(val,oldval){
                    let obj = {
                        name: val.name,
                        parentName: val.meta.parentName,
                        title:  val.meta.title,
                    };
                    val.query?obj.query=val.query:"";
                    this.$store.commit('tagsView/push_tags',obj);
                    this.getTagsView();
                },
                // 深度观察监听
                deep: true
            }
        },
        mounted() {
            this.getTagsView();
        },
        methods:{
            getTagsView(){
                if(sessionStorage.getItem('tags')){
                    this.tagsView = JSON.parse(sessionStorage.getItem('tags'));
                    this.$store.state.tagsView.tags = JSON.parse(sessionStorage.getItem('tags'));
                }
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/css/variables";

    .app-main {
        width: calc(100% - 214px);
        position: relative;
        padding: 24px;
        height: 100%;
        overflow: auto;
        border-radius: 6px;
        .scrollbar();
    }
</style>
