import {getToken, setToken_day} from '@/utils/cookieToken'
import {loginIn} from '@/api/user'
import globalFun from "../../utils/globalFun";

const state = {
  token: '',
  userinfo: {},
};

const getDefaultState = () => {
  return {
    token: getToken(),
    userinfo: {},
  }
};

const mutations = {
  reset_state: (state) => {
    Object.assign(state, getDefaultState())
  },

  set_token: (state, token) => {
    state.token = token;
    setToken_day(token);
  },

  set_userinfo: (state, userinfo) => {
    state.userinfo = userinfo;
    globalFun.localStorage_set('platform_userinfo', userinfo, 7)
  },

  del_userinfo: (state) => {
    state.userinfo = '';
    globalFun.localStorage_del('platform_userinfo');
  },
};

const actions = {
  login({commit}, userinfo) {
    const {username, aesPassword} = userinfo;
    return new Promise((resolve, reject) => {
      loginIn({username: username, password: aesPassword}).then(response => {
        const {data} = response;
        commit('set_token', data.token);
        commit('set_userinfo', data);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

