import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import tagsView from "./modules/tagsView";
import user from "./modules/user";
import sideBar from './modules/sideBar'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    tagsView,
    user,
    sideBar
  },
  getters
});

export default store
