<template>
    <div class="app-wrapper">
        <div class="fixed-header">
            <Header/>
        </div>
        <div class="main-container">
            <side-bar/>
            <app-main/>
        </div>
    </div>
</template>

<script>
    import Header from "./components/Header";
    import SideBar from "./components/SideBar";
    import AppMain from "./components/AppMain";

    export default {
        name: "index",
        components: {
            Header,
            SideBar,
            AppMain
        }
    }
</script>

<style scoped lang="less">
    * {
        box-sizing: border-box;
    }

    .app-wrapper {
        width: 100%;
        height: 100%;
        background-color: #F0F2F5;
    }

    .fixed-header {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 9;
        width: 100%;
        transition: width 0.28s;
    }

    .main-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        align-items: start;
        justify-content: space-between;
        padding-top: 50px;
    }
</style>
