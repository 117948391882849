import globalFun from "../../utils/globalFun";

const state = {
  sidebar: [],
};

const mutations = {
  set_sidebar: (state, sidebar) => {
    state.sidebar = sidebar;
    globalFun.localStorage_set('sidebar', state.sidebar, 7)
  },

  del_sidebar:(state)=>{
    state.sidebar = [];
    globalFun.localStorage_del('sidebar')
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
