import axios from 'axios'
// 根据环境不同引入不同api地址
import {baseApi} from '@/config'
import router from '../router'
import {Message,Loading} from 'element-ui';
import {getToken,removeToken} from "./cookieToken";
import store from "../store";
// create an axios instance
const service = axios.create({
  baseURL: baseApi, // url = base api url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
});
let options = {
  lock: true,
  text: 'Loading',
  background: 'rgba(0, 0, 0, 0.1)'
};

// request拦截器 request interceptor
service.interceptors.request.use(
  config => {

    // 不传递默认开启loading
    if (!config.hideloading) {
     // Loading.service(options);
    }

    if (getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken();
    }

    return config
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error)
  }
);
// respone拦截器
service.interceptors.response.use(

  response => {
    const res = response.data;
    Loading.service(options);
   /* setTimeout(function () {
      if(!res){
        Loading.service(options);
        return service.request(response.config);
      }
    },3000);*/
    if (res.code && res.code !== 200) {
      // 登录超时,重新登录
      if (res.code === -9999) {
        Message({
          message: '请重新登录',
        });
        store.commit('user/del_userinfo');
        store.commit('tagsView/delete_tagsView');
        store.commit('sideBar/del_sidebar');
        removeToken();
        router.push("/login")
      }

      if (res.code === -1 || res.code === -10000) {
        Message({
          message: res.msg+res.data,
        });
      }
      Loading.service(options).close();
      return Promise.reject(res || 'error')
    } else {
      Loading.service(options).close();
      return Promise.resolve(res)

    }
  },
  error => {
    console.log('err' + error); // for debug
    return Promise.reject(error)
  }
);

export default service
